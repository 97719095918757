
div.main_image{
  width: 100%;
  height: 350px;

}
div.main_image img{
  width: 100%;
  height: 350px;
  object-fit:cover;
}
main.product{
  max-width: 980px;
  width: 100%;
  color:#333;
  margin: 20px auto
}

div.main-text{
  margin: 20px 0;
  line-height: 1.4em;
  font-size: .9em;
  color: #777;
  text-align: center;
}

div.product_list{
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
div.product_list div.item-box{
  width: 400px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  cursor: pointer;
}
div.product_list div.item-box:hover{
  opacity: .9;
  border: 2px solid #777;
}
div.product_list div.item-box div.image{
  width: 100%;

}
div.product_list div.item-box div.image img{
  width: 100%;
}
div.product_list div.item-box div.image{
  width: 100%;
}
div.product_list div.item-box div.title{

  margin: 10px 0;
}

div.product_list div.item-box div.price{
  text-align: right;
  font-weight: bold;
  font-size: .9em;
}
/*スマホ*/
@media screen and (max-width: 770px) {
  main.product h1{

    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #333;
  }
  main.product div.header{
    flex-direction: column;
  }
  div.mainphoto{
    flex: 1 1 auto;
  }
}


section.product{
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}
section.product div.left-side{
  width: 500px;
  margin-right: 30px;
}
section.product div.right-side{
  width: calc(100% - 500px);
}

section.product div.left-side div.image-box{
  width: 100%;

  padding: 20px;
  border: 1px solid #ddd;
  cursor: pointer;

}
section.product div.left-side div.image-box img{
  width: 100%;
}

section.product div.left-side div.price{
  margin-top: 20px;
  width: 100%;
  text-align: right;
  font-size: 1.2em;
  border-bottom: dotted 1px #777;
  padding: 12px 10px;
  font-weight: bold;
}
section.product div.title{
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 5px;
  border-bottom: 2px solid var(--color-link);
  padding-bottom: 10px;
}
section.product div.sub-title{
  margin-top: 20px;
  font-size: .9em;
}
section.product div.description{
  width: 100%;
  margin-top: 30px;
}
section.product div.description strong{
  margin: 10px 0;
  line-height: 1.4em;
}
section.product div.description h3{
  margin: 30px 0 5px 0;
  border-left: 2px solid var(--color-link);

  padding: 5px;
}
section.product div.description h4{
  margin-top: 20px;
  margin-bottom: 5px;
}

section.product div.description p{
  margin-bottom: 25px;
  font-size: .9em;
  line-height: 1.4em;
}

div.photos{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;

}
h2.photo{
  width: 100%;
  margin-top: 30px;
  border-bottom: dotted 1px #777;
  font-size: 1.2em;
  margin-bottom: 5px;
  padding-left: 10px;
}
div.photos div{
  width: 200px;
  height: 200px;
  margin: 5px;
}
div.photos div img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}



@media screen and (max-width: 770px) {
  main.product{
    margin:0;
  }
  section.product div.title.head{
    margin: 20px 10px 10px 10px;
  }
  div.main-text{
    padding: 10px 10px 0 10px;
    text-align: left;
    margin-bottom: 0;
  }
  div.product_list{
    flex-direction: column;
    padding: 10px;
    margin-top: 0;
  }
  div.product_list div.item-box{
    max-width: 400px;
    width: 100%;
    padding: 20px;
    margin: 10px 0;
  }

  section.product{
    flex-direction: column;
    margin-top: 0;

  }

  section.product div.left-side{
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  section.product div.right-side{
    width: 100%;
    padding: 10px 15px;

  }
  section.product div.left-side div.image-box{
    border: 0;
    padding: 20px;
    background-color: #eee;
  }
  section.product div.left-side div.price{
    margin-top: 10px;
    margin-bottom: 20px;
    border-bottom: dotted 1px #777;
    padding: 12px 10px;
    font-weight: bold;
  }
  div.photos div{
    width: calc(50vw - 20px);
    height:  calc(50vw - 20px);
  }
}

footer{
  margin-top: 50px;
  border-top: 1px solid #ddd;
  padding: 20px;
  background-color: #eee;
}
div.footer-inner{
  width: 100%;
  max-width: 980px;
  font-size: .8em;
  color:#777;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

div.footer-link{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

div.footer-link a{
  margin: 10px;
  color:#777;
}

div.copyright{
  margin-top: 10px;
  text-align: center;
  width: 100%;
  font-size: .8em;
}
@media screen and (max-width: 770px) {
  div.footer-link{
    flex-direction: column;
  }
}

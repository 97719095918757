main.cart{
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
}

div.cart-row{
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
div.cart-column{
  display: flex;
  flex-direction: column;
    margin: 0 auto;
    max-width: 1000px;
}
section.cart-content{
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  line-height: 1.4em;
  box-sizing: border-box;
}

div.cart-column{
  max-width: 700px;
  width: 100%;
}

section.cart-content h2{
  font-size: 1.5em;
  margin: 40px 0 10px 0;
  padding-bottom: 10px;
  border-bottom: 2px solid #333;

}

section.cart-content table{
  width: 100%;
  font-size: .8em;
  box-sizing: border-box;
}
section.cart-content table tr{
  border-bottom: 1px solid #ddd;
}
section.cart-content table td,
section.cart-content table th{
  padding: 10px;
  letter-spacing:.05em;
  box-sizing: border-box;
}

section.cart-content div.total_price{
  width: 100%;
  margin: 10px 0;
  padding:5px 5px  0 5px;
  width: 100%;
  text-align: right;
  font-size: 1.2em;
  letter-spacing:.05em;
  box-sizing: border-box;
}

section.cart-content div.form{

  display: flex;
  flex-direction: column;
}

div.form-box-item {
  width: 100%;
  margin-top: 20px;
}

div.form-box-item.row {
  display: flex;
  flex-direction: row;
}
div.form-box-item-row-item{
  display:flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}
div.form-box-item-static {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  border-bottom: 1px dotted #ddd;
  padding: 5px;
}
div.form-box-item-static label{
  width: 120px;
  font-weight: bold;
}

section.btn-box div{
  width: 100%;
  text-align: center;
  margin: 20px 0;
  font-size: .8em;
  decoration:underline
}

table.order-list{

}

table.order-list td.t-right{
  text-align: right;
}

@media screen and (max-width: 770px) {
  main.cart{
    padding: 10px;
    box-sizing: border-box;
  }
  section.cart-content.product-info {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  section.cart-content table td,
  section.cart-content table th{
    display: block;
    padding: 2px 5px;
  }


}

main.static{
  max-width: 980px;
  width: 100%;
  color:#333;
  margin: 20px auto
}

main.static h1{
  margin: 50px 0;
  font-size: 1.3em;
  text-align: center;
}

main.static h2{
  margin: 30px 0 8px 0;
  font-weight: bold;
  font-size: 1.1em;
}
main.static p{
  line-height: 1.5em;
}
table.info-table{
  width: 100%;
  font-size: .9em;
  line-height: 1.4em;
}
table.info-table td,
table.info-table th{
  padding: 1em;
  text-align: left;
  border: 1px solid #ddd;

}
table.info-table th{
  background-color: #eee;
  min-width: 150px;
}
@media screen and (max-width: 639px) {
  table.info-table td,
  table.info-table th{
    display: block;
  }
}

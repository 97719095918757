header{
  height: 80px;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
}
header div.header-inner{
  max-width: 980px;
  width: 100%;
  padding: 15px 30px 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}
header div.logo{
  width: 120px;
}
header div.logo img {
  width: 100%;
}

header div.info{
  font-size: .8em;
  padding-top: 40px;
  color:#777;
  margin-left: auto;
}

header div.sub{
  margin-top: 22px;
  margin-left: 30px;
  height: auto;
  background-color: #f97000;
  color:#fff;
  padding: 6px 0;
  width: 250px;
  height: 100%;
  text-align: center;
}
@media screen and (max-width: 770px) {
  header div.info{
    padding-top: 40px;

  }
}
